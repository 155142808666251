import axios from "../config/axios-config"

export const setRequest = (navigate, getAccessTokenSilently) => {
    return async (opts) => {
        const token = await getAccessTokenSilently()

        if (opts.headers) {
            opts.headers.Authorization = `Bearer ${token}`
            opts.headers['ngrok-skip-browser-warning'] = "69420"
        } else {
            opts.headers = {
                "ngrok-skip-browser-warning": "69420",
                "content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }

        try {
            const resp = await axios(opts)

            return resp.data
        } catch (err) {
            // unauthorized
            if (err.response.status === 401 && !window.location.pathname.includes("login")) {
                // check logged in user
                if (window.location.pathname.includes("freeze")) {
                    navigate("/freeze/login")
                } else if (window.location.pathname.includes("family")) {
                    navigate("/family/login")
                } else if (window.location.pathname.includes("staff")) {
                    navigate("/staff/login")
                } else {
                    window.location.origin = "https://www.cofertility.com/"
                }

                return
            }

            throw err
        }
    }
}

export const setRefreshUser = (navigate, setUser, request) => {
    return async () => {
        try {
            const resp = await request({
                url: `/login`,
                method: "POST",
            });

            setUser(resp.user)
        } catch(err) {
            // unauthorized
            if (err.response.status === 401 && !window.location.pathname.includes("login")) {
                // check logged in user
                if (window.location.pathname.includes("freeze") || window.location.pathname.includes("keep")) {
                    navigate("/freeze/login")
                } else if (window.location.pathname.includes("family")) {
                    navigate("/family/login")
                } else if (window.location.pathname.includes("staff")) {
                    navigate("/staff/login")
                } else {
                    window.location.origin = "https://www.cofertility.com/"
                }
            }
        }
    }
}

const isArrayOfObjects = (arr) => {
  if (!Array.isArray(arr)) return false; // Check if it's an array

  return arr.every(item => typeof item === 'object' && item !== null);
};

export const convertFromUnixDate = (unix) => {
    if (!unix) {
        return "";
    }

    const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(unix).toLocaleDateString("en-US", options);
    return formattedDate;
};

export const convertToUnixDate = (timestamp) => {
    const dateObject = new Date(timestamp);
    const unixTimestamp = Math.floor(dateObject.getTime());

    return unixTimestamp;
};

export const extractDate = (dateTimeString) => {
    const options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
    };
    const date = new Date(dateTimeString);
    const formattedDate = date.toLocaleDateString(undefined, options);
    return formattedDate;
};

export const calculateLogTime = (createdAt) => {
    const now = new Date();
    const nowUTC = new Date(now.toUTCString());
    const ll = new Date(createdAt);

    const elapsedInDays = Math.floor((nowUTC - ll) / 60000 / 60 / 24);

    if (elapsedInDays <= 1) {
        return "Today";
    } else if (elapsedInDays < 7) {
        return `${elapsedInDays} Days Ago`;
    } else if (elapsedInDays >= 7 && elapsedInDays < 14) {
        return `Last Week`;
    } else if (elapsedInDays >= 14 && elapsedInDays < 30) {
        return `${Math.floor(elapsedInDays / 7)} Weeks Ago`;
    } else if (elapsedInDays >= 30 && elapsedInDays < 60) {
        return `Last Month`;
    } else if (elapsedInDays >= 60 && elapsedInDays < 365) {
        return `${Math.floor(elapsedInDays / 30)} Months Ago`;
    } else if (elapsedInDays >= 365 && elapsedInDays < 730) {
        return `Last Year`;
    } else if (elapsedInDays >= 730) {
        return `${Math.floor(elapsedInDays / 365)} Years Ago`;
    }

    return "";
};

export const handleCopy = async (toCopy, setCopied) => {
    await navigator.clipboard.writeText(toCopy);
    setCopied(true)
    setTimeout(() => setCopied(false), 2000);
};

export const redirectToFormsort = ({ form, user, preload = null, staff = null, newTab = false }) => {
    let formsortURL = `https://forms.cofertility.com/flow/${form.flow}/variant`

    if (staff) {
        formsortURL += '/optional'
    } else {
        formsortURL += `/${form.variant}`
    }

    let env = process.env.REACT_APP_ENV
    if (env === "local") {
        env = process.env.REACT_APP_STAFF_FIRST_NAME + "_" + env
    }
    
    if (env !== "prod") {
        formsortURL += `?formsortEnv=${env}`
    }

    const htmlForm = document.createElement('form');
    htmlForm.method = "POST"
    htmlForm.action = formsortURL;

    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "user_id";
    input.value = user.id;
    htmlForm.appendChild(input);

    if (staff) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = "staff_id";
        input.value = staff.id;
        htmlForm.appendChild(input);
    }

    let present = {};

    if (form.application && form.application.response) {
        const answers = form.application.response.answers

        Object.keys(answers).forEach((key) => {
            if (key === "formsortEnv" || key === "staff_id" || key === "user_id" || key === "return_to" || key === "family_choices" || key === "redirect" || key === "job_fields_list") {
                return
            } 

            present[key] = true

            if (Array.isArray(answers[key]) && answers[key].length) {
                const isObject = isArrayOfObjects(answers[key])

                if (isObject) {
                    answers[key].forEach((obj, i) => {
                        for (const [prop, val] of Object.entries(obj)) {
                            if (key.includes("ethnicities") && prop === "ethnicity") {
                                const select = document.createElement("select");
                                // select.type = "hidden"
                                select.name = `${key}[${i}][${prop}]`;
                                const option = document.createElement("option");
                                option.value = val;
                                option.selected = true;
                                select.options.add(option);
                                htmlForm.appendChild(select);
                            } else if (prop === "person") {
                                const select = document.createElement("select");
                                // select.type = "hidden"
                                select.name = `${key}[${i}][${prop}]`;
                                const option = document.createElement("option");
                                option.value = val;
                                option.selected = true;
                                select.options.add(option);
                                htmlForm.appendChild(select);
                            } else {
                                const input = document.createElement("input");
                                input.type = "hidden";
                                input.name = `${key}[${i}][${prop}]`;
                                input.value = val;
                                htmlForm.appendChild(input);
                            }
                        }
                    })
                    return
                }

                // todo: check if array of objects for compound 
                const select = document.createElement("select");
                // select.type = "hidden"
                select.name = key;
                select.multiple = true;

                answers[key].forEach((answer) => {
                    const option = document.createElement("option");
                    option.value = answer;
                    option.selected = true;
                    select.options.add(option);
                });
                htmlForm.appendChild(select);
            } else if (typeof answers[key] === 'object' && answers[key] !== null) {
                for (const [prop, val] of Object.entries(answers[key])) {
                    if (key === "address" && prop === "postal_code") present["address[postal_code]"] = true

                    const input = document.createElement("input");
                    input.type = "hidden";
                    input.name = `${key}[${prop}]`;
                    input.value = val;
                    htmlForm.appendChild(input);
                }
            } else {
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = answers[key];
                htmlForm.appendChild(input);
            }
        });
    }

    if (preload !== null) {
        if (preload.application && preload.application.response) {
            const answers = preload.application.response.answers

            Object.keys(answers).forEach((key) => {
                if (!["dob", "first_name", "last_name", "zip", "height_ft", "height_in", "weight", "elig_nicotine_usage", "pronouns", "preferred_name"].includes(key) || present[key]) {
                    return
                }

                if (key === "zip") {
                    if (present["address[postal_code]"]) return

                    const input = document.createElement("input");
                    input.type = "hidden";
                    input.name = `address[postal_code]`;
                    input.value = answers[key];
                    htmlForm.appendChild(input);
                    return
                }

                if (key === "elig_nicotine_usage") {
                    if (present["elig_nicotine_use"]) return

                    const input = document.createElement("input");
                    input.type = "hidden";
                    input.name = "elig_nicotine_use";
                    input.value = answers[key];
                    htmlForm.appendChild(input);
                    return
                }

                if (Array.isArray(answers[key]) && answers[key].length) {
                    const isObject = isArrayOfObjects(answers[key])

                    if (isObject) {
                        answers[key].forEach((obj, i) => {
                            for (const [prop, val] of Object.entries(obj)) {
                                if (key.includes("ethnicities") && prop === "ethnicity") {
                                    const select = document.createElement("select");
                                    // select.type = "hidden"
                                    select.name = `${key}[${i}][${prop}]`;
                                    const option = document.createElement("option");
                                    option.value = val;
                                    option.selected = true;
                                    select.options.add(option);
                                    htmlForm.appendChild(select);
                                    return
                                }
                                
                                if (prop === "person") {
                                    const select = document.createElement("select");
                                    // select.type = "hidden"
                                    select.name = `${key}[${i}][${prop}]`;
                                    const option = document.createElement("option");
                                    option.value = val;
                                    option.selected = true;
                                    select.options.add(option);
                                    htmlForm.appendChild(select);
                                    return
                                }
                                
                                const input = document.createElement("input");
                                input.type = "hidden";
                                input.name = `${key}[${i}][${prop}]`;
                                input.value = val;
                                htmlForm.appendChild(input);
                            }
                        })
                        return
                    }

                    // todo: check if array of objects for compound 
                    const select = document.createElement("select");
                    // select.type = "hidden"
                    select.name = key;
                    select.multiple = true;

                    answers[key].forEach((answer) => {
                        const option = document.createElement("option");
                        option.value = answer;
                        option.selected = true;
                        select.options.add(option);
                    });
                    htmlForm.appendChild(select);
                } else if (typeof answers[key] === 'object' && answers[key] !== null) {
                    for (const [prop, val] of Object.entries(answers[key])) {
                        const input = document.createElement("input");
                        input.type = "hidden";
                        input.name = `${key}[${prop}]`;
                        input.value = val;
                        htmlForm.appendChild(input);
                    }
                } else {
                    const input = document.createElement("input");
                    input.type = "hidden";
                    input.name = key;
                    input.value = answers[key];
                    htmlForm.appendChild(input);
                }
            });
        }
    }

    document.body.appendChild(htmlForm);

    if (newTab) {
        htmlForm.target = "_blank";
    }
        
    htmlForm.submit();
}

export const AuditLogTypeMap = {
    "notes": "a note",
    "users": "user",
    "user_photos": "a photo",
    "files": "a file",
}

export const SchoolRanking = [
    {
        value: "ivy_league",
        label: "Ivy League"
    },
    {
        value: "top_10",
        label: "Top 10 University"
    },
    {
        value: "top_20",
        label: "Top 20 University"
    },
    {
        value: "top_50",
        label: "Top 50 University"
    },
    {
        value: "top_75",
        label: "Top 75 University"
    },
    {
        value: "top_100",
        label: "Top 100 University"
    },
    {
        value: "empty",
        label: "Not applicable or No university attended"
    }
]

export const SchoolRankingMap = {
        "ivy_league": "Ivy League",
        "top_10": "Top 10 University",
        "top_20": "Top 20 University",
        "top_50": "Top 50 University",
        "top_75": "Top 75 University",
        "top_100": "Top 100 University",
        "empty": "Empty"
}

export const DisqualificatinReasons = [
    {
        label: "Self - 1 Ovary",
        slug: "Self - 1 Ovary",
    },
    {
        label: "Self - Adopted",
        slug: "Self - Adopted",
    },
    {
        label: "Self - Age",
        slug: "Self - Age",
    },
    {
        label: "Self - AMH",
        slug: "Self - AMH",
    },
    {
        label: "Self - BMI",
        slug: "Self - BMI",
    },
    {
        label: "Self - Does not know family history",
        slug: "Self - Does not know family history",
    },
    {
        label: "Donor Never Responded",
        slug: "Donor Never Responded",
    },
    {
        label: "Duplicate",
        slug: "Duplicate",
    },
    {
        label: "Family - 3+ miscarriages",
        slug: "Family - 3+ miscarriages",
    },
    {
        label: "Family - Alcoholism/Drug Abuse",
        slug: "Family - Alcoholism/Drug Abuse",
    },
    {
        label: "Family - ALS history",
        slug: "Family - ALS history",
    },
    {
        label: "Family - Alzheimer history",
        slug: "Family - Alzheimer history",
    },
    {
        label: "Family - Aneurysm",
        slug: "Family - Aneurysm",
    },
    {
        label: "Family - Autism",
        slug: "Family - Autism",
    },
    {
        label: "Family - Autoimmune history",
        slug: "Family - Autoimmune history",
    },
    {
        label: "Family - Bipolar",
        slug: "Family - Bipolar",
    },
    {
        label: "Family - Birth defect",
        slug: "Family - Birth defect",
    },
    {
        label: "Family - Bleeding disorder",
        slug: "Family - Bleeding disorder",
    },
    {
        label: "Family - Blindness",
        slug: "Family - Blindness",
    },
    {
        label: "Family - Borderline Disorder",
        slug: "Family - Borderline Disorder",
    },
    {
        label: "Family - Cancer History",
        slug: "Family - Cancer History",
    },
    {
        label: "Family - Celiac Disease",
        slug: "Family - Celiac Disease",
    },
    {
        label: "Family - Cerebral palsy",
        slug: "Family - Cerebral palsy",
    },
    {
        label: "Family - Congenital Cataracts",
        slug: "Family - Congenital Cataracts",
    },
    {
        label: "Family - Connective Tissue Disorders",
        slug: "Family - Connective Tissue Disorders",
    },
    {
        label: "Family - Crohns Disease",
        slug: "Family - Crohns Disease",
    },
    {
        label: "Family - Diabetes",
        slug: "Family - Diabetes",
    },
    {
        label: "Family - Duplicated Ureter",
        slug: "Family - Duplicated Ureter",
    },
    {
        label: "Family - Fibromyalgia",
        slug: "Family - Fibromyalgia",
    },
    {
        label: "Family - Genetic Disorder",
        slug: "Family - Genetic Disorder",
    },
    {
        label: "Family - Glaucoma",
        slug: "Family - Glaucoma",
    },
    {
        label: "Family - Goiter",
        slug: "Family - Goiter",
    },
    {
        label: "Family - Graves disease/ Hyperthyroidism",
        slug: "Family - Graves disease/ Hyperthyroidism",
    },
    {
        label: "Family - Heart attack history",
        slug: "Family - Heart attack history",
    },
    {
        label: "Family - Heart defect",
        slug: "Family - Heart defect",
    },
    {
        label: "Family - Inflammatory Bowel Disease",
        slug: "Family - Inflammatory Bowel Disease",
    },
    {
        label: "Family - Kidney disorder",
        slug: "Family - Kidney disorder",
    },
    {
        label: "Family - Learning disability",
        slug: "Family - Learning disability",
    },
    {
        label: "Family - Long QT Syndrome",
        slug: "Family - Long QT Syndrome",
    },
    {
        label: "Family - Lupus",
        slug: "Family - Lupus",
    },
    {
        label: "Family - Meningioma/neurofibromatosis ",
        slug: "Family - Meningioma/neurofibromatosis ",
    },
    {
        label: "Family - Multiple Endocrine Neoplasia",
        slug: "Family - Multiple Endocrine Neoplasia",
    },
    {
        label: "Family - Multiple Sclerosis ",
        slug: "Family - Multiple Sclerosis ",
    },
    {
        label: "Family - Parkinson history",
        slug: "Family - Parkinson history",
    },
    {
        label: "Family - Pernicious Anemia",
        slug: "Family - Pernicious Anemia",
    },
    {
        label: "Family - Polycystic Kidney Disease",
        slug: "Family - Polycystic Kidney Disease",
    },
    {
        label: "Family - Primary Biliary Sclerosis",
        slug: "Family - Primary Biliary Sclerosis",
    },
    {
        label: "Family - Psoriatic Arthritis",
        slug: "Family - Psoriatic Arthritis",
    },
    {
        label: "Family - Pulmonary Hypertension",
        slug: "Family - Pulmonary Hypertension",
    },
    {
        label: "Family - Rheumatoid Arthritis",
        slug: "Family - Rheumatoid Arthritis",
    },
    {
        label: "Family - Schizophrenia",
        slug: "Family - Schizophrenia",
    },
    {
        label: "Family - Severe anxiety/depression",
        slug: "Family - Severe anxiety/depression",
    },
    {
        label: "Family - Sjrogen's",
        slug: "Family - Sjrogen's",
    },
    {
        label: "Family - Stroke history",
        slug: "Family - Stroke history",
    },
    {
        label: "Family - Suicide attempt",
        slug: "Family - Suicide attempt",
    },
    {
        label: "Family - Thyroid disorders",
        slug: "Family - Thyroid disorders",
    },
    {
        label: "Family - Tourette syndrome",
        slug: "Family - Tourette syndrome",
    },
    {
        label: "Family - Ulcerative colitis",
        slug: "Family - Ulcerative colitis",
    },
    {
        label: "Family - Wolf Parkinson White Syndrome",
        slug: "Family - Wolf Parkinson White Syndrome",
    },
    {
        label: "Family Medical History",
        slug: "Family Medical History",
    },
    // check with chris below, updated label and slug
    {
        label: "Family - Cleft Lip/Palate or Club Foot",
        slug: "Family - Cleft Lip/Palate or Club Foot",
    },
    // check with chris below, updated label and slug
    {
        label: "Family - OCD",
        slug: "Family - OCD",
    },
    {
        label: "Self - Jail time",
        slug: "Self - Jail time",
    },
    {
        label: "Keep Only",
        slug: "Keep Only",
    },
    {
        label: "Self - Lifestyle factors",
        slug: "Self - Lifestyle factors",
    },
    {
        label: "Self - Motivation for donating",
        slug: "Self - Motivation for donating",
    },
    {
        label: "Self - No longer interested",
        slug: "Self - No longer interested",
    },
    {
        label: "Self - Not located in Canada or the United States",
        slug: "Self - Not located in Canada or the United States",
    },
    {
        label: "Self - Past cycle not successful",
        slug: "Self - Past cycle not successful",
    },
    {
        label: "Self - Pausing application",
        slug: "Self - Pausing application",
    },
    {
        label: "Self - Personal Medical History",
        slug: "Self - Personal Medical History",
    },
    {
        label: "Self - Registered Member of a Native American Tribe",
        slug: "Self - Registered Member of a Native American Tribe",
    },
    {
        label: "Self - 2+ miscarriages",
        slug: "Self - 2+ miscarriages",
    },
    {
        label: "Self - Adopted",
        slug: "Self - Adopted",
    },
    {
        label: "Self - Albinism",
        slug: "Self - Albinism",
    },
    {
        label: "Self - Alcoholism/Drug Abuse",
        slug: "Self - Alcoholism/Drug Abuse",
    },
    {
        label: "Self - ALS",
        slug: "Self - ALS",
    },
    {
        label: "Self - Aneurysm",
        slug: "Self - Aneurysm",
    },
    {
        label: "Self - Autism",
        slug: "Self - Autism",
    },
    {
        label: "Self - Autoimmune history",
        slug: "Self - Autoimmune history",
    },
    {
        label: "Self - Bipolar",
        slug: "Self - Bipolar",
    },
    {
        label: "Self - Birth defect",
        slug: "Self - Birth defect",
    },
    {
        label: "Self - Bleeding disorder",
        slug: "Self - Bleeding disorder",
    },
    {
        label: "Self - Blindness",
        slug: "Self - Blindness",
    },
    {
        label: "Self - Borderline Disorder",
        slug: "Self - Borderline Disorder",
    },
    {
        label: "Self - Brain tumor",
        slug: "Self - Brain tumor",
    },
    {
        label: "Self - breastfeeding",
        slug: "Self - breastfeeding",
    },
    {
        label: "Self - Cancer history",
        slug: "Self - Cancer history",
    },
    {
        label: "Self - Celiac Disease",
        slug: "Self - Celiac Disease",
    },
    {
        label: "Self - Cerebral palsy",
        slug: "Self - Cerebral palsy",
    },
    {
        label: "Self - Cirrhosis",
        slug: "Self - Cirrhosis",
    },
    {
        label: "Self - Congenital Cataracts",
        slug: "Self - Congenital Cataracts",
    },
    {
        label: "Self - Connective Tissue Disorders",
        slug: "Self - Connective Tissue Disorders",
    },
    {
        label: "Self - Crohns Disease",
        slug: "Self - Crohns Disease",
    },
    {
        label: "Self - Depo Provera",
        slug: "Self - Depo Provera",
    },
    {
        label: "Self - Diabetes",
        slug: "Self - Diabetes",
    },
    {
        label: "Self - Donated 6 times",
        slug: "Self - Donated 6 times",
    },
    {
        label: "Self - Duplicated Ureter",
        slug: "Self - Duplicated Ureter",
    },
    {
        label: "Self - Education",
        slug: "Self - Education",
    },
    {
        label: "Self - Emphysema/COPD",
        slug: "Self - Emphysema/COPD",
    },
    {
        label: "Self - Endometriosis",
        slug: "Self - Endometriosis",
    },
    {
        label: "Self - Epilepsy",
        slug: "Self - Epilepsy",
    },
    {
        label: "Self - Fibromyalgia",
        slug: "Self - Fibromyalgia",
    },
    {
        label: "Self - Genetic disorder",
        slug: "Self - Genetic disorder",
    },
    {
        label: "Self - Glaucoma",
        slug: "Self - Glaucoma",
    },
    {
        label: "Self - Goiter",
        slug: "Self - Goiter",
    },
    {
        label: "Self - Gout",
        slug: "Self - Gout",
    },
    {
        label: "Self - Graves disease/ Hyperthyroidism",
        slug: "Self - Graves disease/ Hyperthyroidism",
    },
    {
        label: "Self - Hashimoto's",
        slug: "Self - Hashimoto's",
    },
    {
        label: "Self - Hearing problems/deafness",
        slug: "Self - Hearing problems/deafness",
    },
    {
        label: "Self - Heart attack",
        slug: "Self - Heart attack",
    },
    {
        label: "Self - Heart defect",
        slug: "Self - Heart defect",
    },
    {
        label: "Self - Hepatitis B/C",
        slug: "Self - Hepatitis B/C",
    },
    {
        label: "Self - Hysterectomy",
        slug: "Self - Hysterectomy",
    },
    {
        label: "Self - Infertility",
        slug: "Self - Infertility",
    },
    {
        label: "Self - Inflammatory Bowel Disease",
        slug: "Self - Inflammatory Bowel Disease",
    },
    {
        label: "Self - Kidney disorder",
        slug: "Self - Kidney disorder",
    },
    {
        label: "Self - Learning disability",
        slug: "Self - Learning disability",
    },
    {
        label: "Self - Long QT Syndrome",
        slug: "Self - Long QT Syndrome",
    },
    {
        label: "Self - Lupus",
        slug: "Self - Lupus",
    },
    {
        label: "Self - Meningioma/neurofibromatosis ",
        slug: "Self - Meningioma/neurofibromatosis ",
    },
    {
        label: "Self - Multiple Endocrine Neoplasia",
        slug: "Self - Multiple Endocrine Neoplasia",
    },
    {
        label: "Self - Multiple Sclerosis ",
        slug: "Self - Multiple Sclerosis ",
    },
    {
        label: "Self - Nicotine use",
        slug: "Self - Nicotine use",
    },
    {
        label: "Self - OCD",
        slug: "Self - OCD",
    },
    {
        label: "Self - past cycle outcomes",
        slug: "Self - past cycle outcomes",
    },
    {
        label: "Self - Pernicious Anemia",
        slug: "Self - Pernicious Anemia",
    },
    {
        label: "Self - PMDD",
        slug: "Self - PMDD",
    },
    {
        label: "Self - Polycystic Kidney Disease",
        slug: "Self - Polycystic Kidney Disease",
    },
    {
        label: "Self - POTS",
        slug: "Self - POTS",
    },
    {
        label: "Self - Primary Biliary Sclerosis",
        slug: "Self - Primary Biliary Sclerosis",
    },
    {
        label: "Self - Psoriatic Arthritis",
        slug: "Self - Psoriatic Arthritis",
    },
    {
        label: "Self - Psych Strikes",
        slug: "Self - Psych Strikes",
    },
    {
        label: "Self - Psychiatric hospitalization",
        slug: "Self - Psychiatric hospitalization",
    },
    {
        label: "Self - Psychiatric Medications",
        slug: "Self - Psychiatric Medications",
    },
    {
        label: "Self - Pulmonary Hypertension",
        slug: "Self - Pulmonary Hypertension",
    },
    {
        label: "Self - Rheumatoid Arthritis",
        slug: "Self - Rheumatoid Arthritis",
    },
    {
        label: "Self - Schizophrenia",
        slug: "Self - Schizophrenia",
    },
    {
        label: "Self - Severe anxiety/depression",
        slug: "Self - Severe anxiety/depression",
    },
    {
        label: "Self - Severe Psoriasis",
        slug: "Self - Severe Psoriasis",
    },
    {
        label: "Self - Sickle Cell Anemia",
        slug: "Self - Sickle Cell Anemia",
    },
    {
        label: "Self - Sjrogen's",
        slug: "Self - Sjrogen's",
    },
    {
        label: "Self - STD",
        slug: "Self - STD",
    },
    {
        label: "Self - STD Exposure",
        slug: "Self - STD Exposure",
    },
    {
        label: "Self - Stillborn",
        slug: "Self - Stillborn",
    },
    {
        label: "Self - Stroke",
        slug: "Self - Stroke",
    },
    {
        label: "Self - Suicide attempt",
        slug: "Self - Suicide attempt",
    },
    {
        label: "Self - Thyroid disorder",
        slug: "Self - Thyroid disorder",
    },
    {
        label: "Self - Tourette syndrome",
        slug: "Self - Tourette syndrome",
    },
    {
        label: "Self - Ulcerative colitis",
        slug: "Self - Ulcerative colitis",
    },
    {
        label: "Self - Von Willebrand Disease",
        slug: "Self - Von Willebrand Disease",
    },
    {
        label: "Self - Weight loss medications",
        slug: "Self - Weight loss medications",
    },
    {
        label: "Self - Wolf Parkinson White Syndrome",
        slug: "Self - Wolf Parkinson White Syndrome",
    },
    // check with chris below, updated label and slug
    {
        label: "Self - Addison’s Disease",
        slug: "Self - Addison’s Disease",
    },
    // check with chris below, updated label and slug
    {
        label: "Self - Club Foot",
        slug: "Self - Club Foot",
    },
    // check with chris below, updated label and slug
    {
        label: "Self - Narcolepsy",
        slug: "Self - Narcolepsy",
    },
    {
        label: "Self - weight loss surgery",
        slug: "Self - weight loss surgery",
    },
    {
        label: "Self - smoking",
        slug: "Self - smoking",
    },
]

export const AmericanStates = [
    {
        label: "Alabama",
        value: "AL"
    },
    {
        label: "Alaska",
        value: "AK"
    },
    {
        label: "Arizona",
        value: "AZ"
    },
    {
        label: "Arkansas",
        value: "AR"
    },
    {
        label: "California",
        value: "CA"
    },
    {
        label: "Colorado",
        value: "CO"
    },
    {
        label: "Connecticut",
        value: "CT"
    },
    {
        label: "Delaware",
        value: "DE"
    },
    {
        label: "Florida",
        value: "FL"
    },
    {
        label: "Georgia",
        value: "GA"
    },
    {
        label: "Hawaii",
        value: "HI"
    },
    {
        label: "Idaho",
        value: "ID"
    },
    {
        label: "Illinois",
        value: "IL"
    },
    {
        label: "Indiana",
        value: "IN"
    },
    {
        label: "Iowa",
        value: "IA"
    },
    {
        label: "Kansas",
        value: "KS"
    },
    {
        label: "Kentucky",
        value: "KY"
    },
    {
        label: "Louisiana",
        value: "LA"
    },
    {
        label: "Maine",
        value: "ME"
    },
    {
        label: "Maryland",
        value: "MD"
    },
    {
        label: "Massachusetts",
        value: "MA"
    },
    {
        label: "Michigan",
        value: "MI"
    },
    {
        label: "Minnesota",
        value: "MN"
    },
    {
        label: "Mississippi",
        value: "MS"
    },
    {
        label: "Missouri",
        value: "MO"
    },
    {
        label: "Montana",
        value: "MT"
    },
    {
        label: "Nebraska",
        value: "NE"
    },
    {
        label: "Nevada",
        value: "NV"
    },
    {
        label: "New Hampshire",
        value: "NH"
    },
    {
        label: "New Jersey",
        value: "NJ"
    },
    {
        label: "New Mexico",
        value: "NM"
    },
    {
        label: "New York",
        value: "NY"
    },
    {
        label: "North Carolina",
        value: "NC"
    },
    {
        label: "North Dakota",
        value: "ND"
    },
    {
        label: "Ohio",
        value: "OH"
    },
    {
        label: "Oklahoma",
        value: "OK"
    },
    {
        label: "Oregon",
        value: "OR"
    },
    {
        label: "Pennsylvania",
        value: "PA"
    },
    {
        label: "Rhode Island",
        value: "RI"
    },
    {
        label: "South Carolina",
        value: "SC"
    },
    {
        label: "South Dakota",
        value: "SD"
    },
    {
        label: "Tennessee",
        value: "TN"
    },
    {
        label: "Texas",
        value: "TX"
    },
    {
        label: "Utah",
        value: "UT"
    },
    {
        label: "Vermont",
        value: "VT"
    },
    {
        label: "Virginia",
        value: "VA"
    },
    {
        label: "Washington",
        value: "WA"
    },
    {
        label: "West Virginia",
        value: "WV"
    },
    {
        label: "Wisconsin",
        value: "WI"
    },
    {
        label: "Wyoming",
        value: "WY"
    },
];

export const AmericanStatesMap = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "FL": "Florida",
    "GA": "Georgia",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PA": "Pennsylvania",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming",
}

export const Races = [
    { 
        label: "Black or African American",
        value: "black_african_american",
    },
    { 
        label: "East Asian or Southeast Asian",
        value: "east_se_asian",
    },
    { 
        label: "Hispanic/Latine",
        value: "hispanic_latine",
    },
    { 
        label: "Indigenous American or Alaska Native",
        value: "indigenous_american_alaska_native",
    },
    { 
        label: "Middle Eastern or North African",
        value: "middle_eastern_north_african",
    },
    { 
        label: "Native Hawaiian or Pacific Islander",
        value: "native_hawaiian_pacific_islander",
    },
    { 
        label: "South Asian",
        value: "south_asian",
    },
    { 
        label: "White",
        value: "white",
    },
]

export const RaceMap = {
    "black_african_american": "Black or African American",
    "east_se_asian": "East Asian or Southeast Asian",
    "hispanic_latine": "Hispanic/Latine",
    "indigenous_american_alaska_native": "Indigenous American or Alaska Native",
    "middle_eastern_north_african": "Middle Eastern or North African",
    "native_hawaiian_pacific_islander": "Native Hawaiian or Pacific Islander",
    "south_asian": "South Asian",
    "white": "White",
}
  
export const Ethnicities = [
    {
        "label": "Aboriginal Australian",
        "value": "aboriginal_australian"
    },
    {
        "label": "Achinese",
        "value": "achinese"
    },
    {
        "label": "Afar",
        "value": "afar"
    },
    {
        "label": "Afghan",
        "value": "afghan"
    },
    {
        "label": "Afro-Caribbean",
        "value": "afro_caribbean",
    },
    {
        "label": "African American",
        "value": "african_american"
    },
    {
        "label": "Ainu",
        "value": "ainu"
    },
    {
        "label": "Akan",
        "value": "akan"
    },
    {
        "label": "Alaskan Native",
        "value": "alaskan_native"
    },
    {
        "label": "Albanian",
        "value": "albanian"
    },
    {
        "label": "Algerian",
        "value": "algerian"
    },
    {
        "label": "Alur",
        "value": "alur"
    },
    {
        "label": "Amhara",
        "value": "amhara"
    },
    {
        "label": "Andorran",
        "value": "andorran"
    },
    {
        "label": "Angolan",
        "value": "angolan"
    },
    {
        "label": "Anguillan",
        "value": "anguillan"
    },
    {
        "label": "Antiguan and Barbudan",
        "value": "antiguan_and_barbudan"
    },
    {
        "label": "Arab",
        "value": "arab"
    },
    {
        "label": "Argentine",
        "value": "argentine"
    },
    {
        "label": "Armenian",
        "value": "armenian"
    },
    {
        "label": "Aromanian",
        "value": "aromanian"
    },
    {
        "label": "Aruban",
        "value": "aruban"
    },
    {
        "label": "Ashanti",
        "value": "ashanti"
    },
    {
        "label": "Ashkenazi Jewish",
        "value": "ashkenazi_jewish"
    },
    {
        "label": "Assyrian",
        "value": "assyrian"
    },
    {
        "label": "Australian",
        "value": "australian"
    },
    {
        "label": "Austrian",
        "value": "austrian"
    },
    {
        "label": "Azerbaijani",
        "value": "azerbaijani"
    },
    {
        "label": "Bahamian",
        "value": "bahamian"
    },
    {
        "label": "Bahraini",
        "value": "bahraini"
    },
    {
        "label": "Bakongo",
        "value": "bakongo"
    },
    {
        "label": "Baloch",
        "value": "baloch"
    },
    {
        "label": "Bambara",
        "value": "bambara"
    },
    {
        "label": "Barbadian",
        "value": "barbadian"
    },
    {
        "label": "Basaa",
        "value": "basaa"
    },
    {
        "label": "Basotho",
        "value": "basotho"
    },
    {
        "label": "Basque",
        "value": "basque"
    },
    {
        "label": "Bedouin",
        "value": "bedouin"
    },
    {
        "label": "Belarusian",
        "value": "belarusian"
    },
    {
        "label": "Belgian",
        "value": "belgian"
    },
    {
        "label": "Belizean",
        "value": "belizean"
    },
    {
        "label": "Bemba",
        "value": "bemba"
    },
    {
        "label": "Bengali",
        "value": "bengali"
    },
    {
        "label": "Beninese",
        "value": "beninese"
    },
    {
        "label": "Berber",
        "value": "berber"
    },
    {
        "label": "Bermudian",
        "value": "bermudian"
    },
    {
        "label": "Bet‍i-Pahuin",
        "value": "bet‍i-pahuin"
    },
    {
        "label": "Bhutanese",
        "value": "bhutanese"
    },
    {
        "label": "Bini",
        "value": "bini"
    },
    {
        "label": "Bolivian",
        "value": "bolivian"
    },
    {
        "label": "Bonairean",
        "value": "bonairean"
    },
    {
        "label": "Bosniak",
        "value": "bosniak"
    },
    {
        "label": "Bosnian",
        "value": "bosnian"
    },
    {
        "label": "Bos‍o",
        "value": "bos‍o"
    },
    {
        "label": "Botswanan",
        "value": "botswanan"
    },
    {
        "label": "Brahui",
        "value": "brahui"
    },
    {
        "label": "Brazilian",
        "value": "brazilian"
    },
    {
        "label": "Breton",
        "value": "breton"
    },
    {
        "label": "British Virgin Islander",
        "value": "british_virgin_islander"
    },
    {
        "label": "Bulgarian",
        "value": "bulgarian"
    },
    {
        "label": "Burmese",
        "value": "burmese"
    },
    {
        "label": "Cambodian",
        "value": "cambodian"
    },
    {
        "label": "Cameroonian",
        "value": "cameroonian"
    },
    {
        "label": "Cape Verdean",
        "value": "cape_verdean",
    },
    {
        "label": "Catalan",
        "value": "catalan"
    },
    {
        "label": "Caymanian",
        "value": "caymanian"
    },
    {
        "label": "Chadian",
        "value": "chadian"
    },
    {
        "label": "Chechen",
        "value": "chechen"
    },
    {
        "label": "Chewa",
        "value": "chewa"
    },
    {
        "label": "Chilean",
        "value": "chilean"
    },
    {
        "label": "Chinese",
        "value": "chinese"
    },
    {
        "label": "Colombian",
        "value": "colombian"
    },
    {
        "label": "Congolese",
        "value": "congolese"
    },
    {
        "label": "Cornish",
        "value": "cornish"
    },
    {
        "label": "Corsican",
        "value": "corsican"
    },
    {
        "label": "Costa Rican",
        "value": "costa_rican"
    },
    {
        "label": "Croatian",
        "value": "croatian"
    },
    {
        "label": "Cuban",
        "value": "cuban"
    },
    {
        "label": "Curaçaoan",
        "value": "curaçaoan"
    },
    {
        "label": "Cypriot",
        "value": "cypriot"
    },
    {
        "label": "Czech",
        "value": "czech"
    },
    {
        "label": "Danish",
        "value": "danish"
    },
    {
        "label": "Dogon",
        "value": "dogon"
    },
    {
        "label": "Dominican",
        "value": "dominican"
    },
    {
        "label": "Druze",
        "value": "druze"
    },
    {
        "label": "Dutch",
        "value": "dutch"
    },
    {
        "label": "Ecuadorian",
        "value": "ecuadorian"
    },
    {
        "label": "Egyptian",
        "value": "egyptian"
    },
    {
        "label": "Emirati",
        "value": "emirati"
    },
    {
        "label": "English",
        "value": "english"
    },
    {
        "label": "Eritrean",
        "value": "eritrean"
    },
    {
        "label": "Estonian",
        "value": "estonian"
    },
    {
        "label": "Ethiopian",
        "value": "ethiopian"
    },
    {
        "label": "Ewe",
        "value": "ewe"
    },
    {
        "label": "Fang",
        "value": "fang"
    },
    {
        "label": "Faroese",
        "value": "faroese"
    },
    {
        "label": "Fijian",
        "value": "fijian"
    },
    {
        "label": "Filipino",
        "value": "filipino"
    },
    {
        "label": "Finnish",
        "value": "finnish"
    },
    {
        "label": "Flemish",
        "value": "flemish"
    },
    {
        "label": "French",
        "value": "french"
    },
    {
        "label": "French Canadian",
        "value": "french_canadian"
    },
    {
        "label": "Frisian",
        "value": "frisian"
    },
    {
        "label": "Fulani",
        "value": "fulani"
    },
    {
        "label": "Gabonese",
        "value": "gabonese"
    },
    {
        "label": "Gambian",
        "value": "gambian"
    },
    {
        "label": "Ganda",
        "value": "ganda"
    },
    {
        "label": "Georgian",
        "value": "georgian"
    },
    {
        "label": "German",
        "value": "german"
    },
    {
        "label": "Ghanaian",
        "value": "ghanaian"
    },
    {
        "label": "Greek",
        "value": "greek"
    },
    {
        "label": "Grenadian",
        "value": "grenadian"
    },
    {
        "label": "Guadeloupean",
        "value": "guadeloupean"
    },
    {
        "label": "Guatemalan",
        "value": "guatemalan"
    },
    {
        "label": "Gujarati",
        "value": "gujarati"
    },
    {
        "label": "Guyanese",
        "value": "guyanese"
    },
    {
        "label": "Guyanese Indian",
        "value": "guyanese_indian"
    },
    {
        "label": "Haitian",
        "value": "haitian"
    },
    {
        "label": "Hausa",
        "value": "hausa"
    },
    {
        "label": "Hawaiian",
        "value": "hawaiian"
    },
    {
        "label": "Hazara",
        "value": "hazara"
    },
    {
        "label": "Hmong",
        "value": "hmong"
    },
    {
        "label": "Honduran",
        "value": "honduran"
    },
    {
        "label": "Hungarian",
        "value": "hungarian"
    },
    {
        "label": "Icelandic",
        "value": "icelandic"
    },
    {
        "label": "Igbo",
        "value": "igbo"
    },
    {
        "label": "Indian",
        "value": "indian"
    },
    {
        "label": "Indigenous American",
        "value": "indigenous_american"
    },
    {
        "label": "Indonesian",
        "value": "indonesian"
    },
    {
        "label": "Inuit",
        "value": "inuit"
    },
    {
        "label": "Iranian",
        "value": "iranian"
    },
    {
        "label": "Iraqi",
        "value": "iraqi"
    },
    {
        "label": "Irish",
        "value": "irish"
    },
    {
        "label": "Israeli",
        "value": "israeli"
    },
    {
        "label": "Italian",
        "value": "italian"
    },
    {
        "label": "Ivorian",
        "value": "ivorian"
    },
    {
        "label": "Jamaican",
        "value": "jamaican"
    },
    {
        "label": "Japanese",
        "value": "japanese"
    },
    {
        "label": "Javanese",
        "value": "javanese"
    },
    {
        "label": "Jordanian",
        "value": "jordanian"
    },
    {
        "label": "Kalenjin",
        "value": "kalenjin"
    },
    {
        "label": "Kamba",
        "value": "kamba"
    },
    {
        "label": "Karelian",
        "value": "karelian"
    },
    {
        "label": "Kazakh",
        "value": "kazakh"
    },
    {
        "label": "Kenyan",
        "value": "kenyan"
    },
    {
        "label": "Kikuyu",
        "value": "kikuyu"
    },
    {
        "label": "Kittitian and Nevisian",
        "value": "kittitian_and_nevisian"
    },
    {
        "label": "Komi",
        "value": "komi"
    },
    {
        "label": "Kongo",
        "value": "kongo"
    },
    {
        "label": "Korean",
        "value": "korean"
    },
    {
        "label": "Kpelle",
        "value": "kpelle"
    },
    {
        "label": "Kru",
        "value": "kru"
    },
    {
        "label": "Kumam",
        "value": "kumam"
    },
    {
        "label": "Kurdish",
        "value": "kurdish"
    },
    {
        "label": "Kuwaiti",
        "value": "kuwaiti"
    },
    {
        "label": "Kyrgyz",
        "value": "kyrgyz"
    },
    {
        "label": "Laotian",
        "value": "laotian"
    },
    {
        "label": "Latvian",
        "value": "latvian"
    },
    {
        "label": "Lebanese",
        "value": "lebanese"
    },
    {
        "label": "Liberian",
        "value": "liberian"
    },
    {
        "label": "Libyans",
        "value": "libyans"
    },
    {
        "label": "Lingala",
        "value": "lingala"
    },
    {
        "label": "Lithuanian",
        "value": "lithuanian"
    },
    {
        "label": "Luba",
        "value": "luba"
    },
    {
        "label": "Luganda",
        "value": "luganda"
    },
    {
        "label": "Lunda",
        "value": "lunda"
    },
    {
        "label": "Luo",
        "value": "luo"
    },
    {
        "label": "Luxembourgish",
        "value": "luxembourgish"
    },
    {
        "label": "Macedonian",
        "value": "macedonian"
    },
    {
        "label": "Madagascan",
        "value": "madagascan"
    },
    {
        "label": "Malagasy",
        "value": "malagasy"
    },
    {
        "label": "Malaysian",
        "value": "malaysian"
    },
    {
        "label": "Malian",
        "value": "malian"
    },
    {
        "label": "Maltese",
        "value": "maltese"
    },
    {
        "label": "Mandinka",
        "value": "mandinka"
    },
    {
        "label": "Manx",
        "value": "manx"
    },
    {
        "label": "Maori",
        "value": "maori"
    },
    {
        "label": "Martinican",
        "value": "martinican"
    },
    {
        "label": "Mauritian",
        "value": "mauritian"
    },
    {
        "label": "Mayan",
        "value": "mayan"
    },
    {
        "label": "Mexican",
        "value": "mexican"
    },
    {
        "label": "Moldovan",
        "value": "moldovan"
    },
    {
        "label": "Mongolian",
        "value": "mongolian"
    },
    {
        "label": "Montenegrin",
        "value": "montenegrin"
    },
    {
        "label": "Montserratian",
        "value": "montserratian"
    },
    {
        "label": "Moroccan",
        "value": "moroccan"
    },
    {
        "label": "Mossi",
        "value": "mossi"
    },
    {
        "label": "Native Pacific Islander",
        "value": "native_pacific_islander"
    },
    {
        "label": "Ndebele",
        "value": "ndebele"
    },
    {
        "label": "Nenets",
        "value": "nenets"
    },
    {
        "label": "Nepali",
        "value": "nepali"
    },
    {
        "label": "Nicaraguan",
        "value": "nicaraguan"
    },
    {
        "label": "Nigerian",
        "value": "nigerian"
    },
    {
        "label": "Norwegian",
        "value": "norwegian"
    },
    {
        "label": "Not sure",
        "value": "not_sure"
    },
    {
        "label": "Omani",
        "value": "omani"
    },
    {
        "label": "Oromo",
        "value": "oromo"
    },
    {
        "label": "Ossetian",
        "value": "ossetian"
    },
    {
        "label": "Other",
        "value": "other"
    },
    {
        "label": "Pakistani",
        "value": "pakistani"
    },
    {
        "label": "Palauan",
        "value": "palauan"
    },
    {
        "label": "Palestinian",
        "value": "palestinian"
    },
    {
        "label": "Panamanian",
        "value": "panamanian"
    },
    {
        "label": "Paraguayan",
        "value": "paraguayan"
    },
    {
        "label": "Parsi",
        "value": "parsi"
    },
    {
        "label": "Pashtun",
        "value": "pashtun"
    },
    {
        "label": "Persian",
        "value": "persian"
    },
    {
        "label": "Peruvian",
        "value": "peruvian"
    },
    {
        "label": "Peul",
        "value": "peul"
    },
    {
        "label": "Polish",
        "value": "polish"
    },
    {
        "label": "Portuguese",
        "value": "portuguese"
    },
    {
        "label": "Puerto Rican",
        "value": "puerto_rican"
    },
    {
        "label": "Punjabi",
        "value": "punjabi"
    },
    {
        "label": "Qatari",
        "value": "qatari"
    },
    {
        "label": "Rohingya",
        "value": "rohingya"
    },
    {
        "label": "Romanian",
        "value": "romanian"
    },
    {
        "label": "Russian",
        "value": "russian"
    },
    {
        "label": "Rwandan",
        "value": "rwandan"
    },
    {
        "label": "Saint Lucian",
        "value": "saint_lucian"
    },
    {
        "label": "Saint Vincentian and Grenadine",
        "value": "saint_vincentian_and_grenadine"
    },
    {
        "label": "Salvadoran",
        "value": "salvadoran"
    },
    {
        "label": "Sami",
        "value": "sami"
    },
    {
        "label": "Samoan",
        "value": "samoan"
    },
    {
        "label": "Saudi Arabian",
        "value": "saudi_arabian"
    },
    {
        "label": "Scandinavian",
        "value": "scandinavian",
    },
    {
        "label": "Scottish",
        "value": "scottish"
    },
    {
        "label": "Senufo",
        "value": "senufo"
    },
    {
        "label": "Sephardic Jewish",
        "value": "sephardic_jewish",
    },
    {
        "label": "Serbian",
        "value": "serbian"
    },
    {
        "label": "Shona",
        "value": "shona"
    },
    {
        "label": "Sicilian",
        "value": "sicilian"
    },
    {
        "label": "Sierra Leonean",
        "value": "sierra_leonean"
    },
    {
        "label": "Sindhi",
        "value": "sindhi"
    },
    {
        "label": "Singaporean",
        "value": "singaporean"
    },
    {
        "label": "Sint Maarten",
        "value": "sint_maarten"
    },
    {
        "label": "Slavic",
        "value": "slavic"
    },
    {
        "label": "Slovak",
        "value": "slovak"
    },
    {
        "label": "Slovene",
        "value": "slovene"
    },
    {
        "label": "Soga",
        "value": "soga"
    },
    {
        "label": "Somali",
        "value": "somali"
    },
    {
        "label": "Songhai",
        "value": "songhai"
    },
    {
        "label": "Sorbian",
        "value": "sorbian"
    },
    {
        "label": "Sotho",
        "value": "sotho"
    },
    {
        "label": "Spanish",
        "value": "spanish"
    },
    {
        "label": "Sri Lankan",
        "value": "sri_lankan"
    },
    {
        "label": "Sudanese",
        "value": "sudanese"
    },
    {
        "label": "Surinamese",
        "value": "surinamese"
    },
    {
        "label": "Swazi",
        "value": "swazi"
    },
    {
        "label": "Swedish",
        "value": "swedish"
    },
    {
        "label": "Swiss",
        "value": "swiss"
    },
    {
        "label": "Syrian",
        "value": "syrian"
    },
    {
        "label": "Taiwanese",
        "value": "taiwanese"
    },
    {
        "label": "Tajik",
        "value": "tajik"
    },
    {
        "label": "Tamil",
        "value": "tamil"
    },
    {
        "label": "Tanzanians",
        "value": "tanzanians"
    },
    {
        "label": "Thai",
        "value": "thai"
    },
    {
        "label": "Tibetan",
        "value": "tibetan"
    },
    {
        "label": "Tigrinya",
        "value": "tigrinya"
    },
    {
        "label": "Togolese",
        "value": "togolese",
    },
    {
        "label": "Tongan",
        "value": "tongan"
    },
    {
        "label": "Trinidadian and Tobagonian",
        "value": "trinidadian_and_tobagonian"
    },
    {
        "label": "Tshiluba",
        "value": "tshiluba"
    },
    {
        "label": "Tuareg",
        "value": "tuareg"
    },
    {
        "label": "Tunisian",
        "value": "tunisian"
    },
    {
        "label": "Turkish",
        "value": "turkish"
    },
    {
        "label": "Turkmen",
        "value": "turkmen"
    },
    {
        "label": "Turks and Caicos Islander",
        "value": "turks_and_caicos_islander"
    },
    {
        "label": "Tutsi",
        "value": "tutsi"
    },
    {
        "label": "U.S. Virgin Islander",
        "value": "u.s._virgin_islander"
    },
    {
        "label": "Ugandan",
        "value": "ugandan"
    },
    {
        "label": "Uighur",
        "value": "uighur"
    },
    {
        "label": "Ukrainian",
        "value": "ukrainian"
    },
    {
        "label": "Uruguayan",
        "value": "uruguayan"
    },
    {
        "label": "Uzbek",
        "value": "uzbek"
    },
    {
        "label": "Venezuelan",
        "value": "venezuelan"
    },
    {
        "label": "Vietnamese",
        "value": "vietnamese"
    },
    {
        "label": "Welsh",
        "value": "welsh"
    },
    {
        "label": "Wolof",
        "value": "wolof"
    },
    {
        "label": "Xhosa",
        "value": "xhosa"
    },
    {
        "label": "Yakut",
        "value": "yakut"
    },
    {
        "label": "Yemeni",
        "value": "yemeni"
    },
    {
        "label": "Yoruba",
        "value": "yoruba"
    },
    {
        "label": "Zande",
        "value": "zande"
    },
    {
        "label": "Zimbabwean",
        "value": "zimbabwean"
    },
    {
        "label": "Zulu",
        "value": "zulu"
    }
]

export const EthnicitiesMap = {
        "aboriginal_australian": "Aboriginal Australian",
        "achinese": "Achinese",
        "afar": "Afar",
        "afghan": "Afghan",
        "afro_caribbean": "Afro-Caribbean",
        "african_american": "African American",
        "ainu": "Ainu",
        "akan": "Akan",
        "alaskan_native": "Alaskan Native",
        "albanian": "Albanian",
        "algerian": "Algerian",
        "alur": "Alur",
        "amhara": "Amhara",
        "andorran": "Andorran",
        "angolan": "Angolan",
        "anguillan": "Anguillan",
        "antiguan_and_barbudan": "Antiguan and Barbudan",
        "arab": "Arab",
        "argentine": "Argentine",
        "armenian": "Armenian",
        "aromanian": "Aromanian",
        "aruban": "Aruban",
        "ashanti": "Ashanti",
        "ashkenazi_jewish": "Ashkenazi Jewish",
        "assyrian": "Assyrian",
        "australian": "Australian",
        "austrian": "Austrian",
        "azerbaijani": "Azerbaijani",
        "bahamian": "Bahamian",
        "bahraini": "Bahraini",
        "bakongo": "Bakongo",
        "baloch": "Baloch",
        "bambara": "Bambara",
        "barbadian": "Barbadian",
        "basaa": "Basaa",
        "basotho": "Basotho",
        "basque": "Basque",
        "bedouin": "Bedouin",
        "belarusian": "Belarusian",
        "belgian": "Belgian",
        "belizean": "Belizean",
        "bemba": "Bemba",
        "bengali": "Bengali",
        "beninese": "Beninese",
        "berber": "Berber",
        "bermudian": "Bermudian",
        "bet‍i-pahuin": "Bet‍i-Pahuin",
        "bhutanese": "Bhutanese",
        "bini": "Bini",
        "bolivian": "Bolivian",
        "bonairean": "Bonairean",
        "bosniak": "Bosniak",
        "bosnian": "Bosnian",
        "bos‍o": "Bos‍o",
        "botswanan": "Botswanan",
        "brahui": "Brahui",
        "brazilian": "Brazilian",
        "breton": "Breton",
        "british_virgin_islander": "British Virgin Islander",
        "bulgarian": "Bulgarian",
        "burmese": "Burmese",
        "cambodian": "Cambodian",
        "cameroonian": "Cameroonian",
        "cape_verdean": "Cape Verdean",
        "catalan": "Catalan",
        "caymanian": "Caymanian",
        "chadian": "Chadian",
        "chechen": "Chechen",
        "chewa": "Chewa",
        "chilean": "Chilean",
        "chinese": "Chinese",
        "colombian": "Colombian",
        "congolese": "Congolese",
        "cornish": "Cornish",
        "corsican": "Corsican",
        "costa_rican": "Costa Rican",
        "croatian": "Croatian",
        "cuban": "Cuban",
        "curaçaoan": "Curaçaoan",
        "cypriot": "Cypriot",
        "czech": "Czech",
        "danish": "Danish",
        "dogon": "Dogon",
        "dominican": "Dominican",
        "druze": "Druze",
        "dutch": "Dutch",
        "ecuadorian": "Ecuadorian",
        "egyptian": "Egyptian",
        "emirati": "Emirati",
        "english": "English",
        "eritrean": "Eritrean",
        "estonian": "Estonian",
        "ethiopian": "Ethiopian",
        "ewe": "Ewe",
        "fang": "Fang",
        "faroese": "Faroese",
        "fijian": "Fijian",
        "filipino": "Filipino",
        "finnish": "Finnish",
        "flemish": "Flemish",
        "french": "French",
        "french_canadian": "French Canadian",
        "frisian": "Frisian",
        "fulani": "Fulani",
        "gabonese": "Gabonese",
        "gambian": "Gambian",
        "ganda": "Ganda",
        "georgian": "Georgian",
        "german": "German",
        "ghanaian": "Ghanaian",
        "greek": "Greek",
        "grenadian": "Grenadian",
        "guadeloupean": "Guadeloupean",
        "guatemalan": "Guatemalan",
        "gujarati": "Gujarati",
        "guyanese": "Guyanese",
        "guyanese_indian": "Guyanese Indian",
        "haitian": "Haitian",
        "hausa": "Hausa",
        "hawaiian": "Hawaiian",
        "hazara": "Hazara",
        "hmong": "Hmong",
        "honduran": "Honduran",
        "hungarian": "Hungarian",
        "icelandic": "Icelandic",
        "igbo": "Igbo",
        "indian": "Indian",
        "indigenous_american": "Indigenous American",
        "indonesian": "Indonesian",
        "inuit": "Inuit",
        "iranian": "Iranian",
        "iraqi": "Iraqi",
        "irish": "Irish",
        "israeli": "Israeli",
        "italian": "Italian",
        "ivorian": "Ivorian",
        "jamaican": "Jamaican",
        "japanese": "Japanese",
        "javanese": "Javanese",
        "jordanian": "Jordanian",
        "kalenjin": "Kalenjin",
        "kamba": "Kamba",
        "karelian": "Karelian",
        "kazakh": "Kazakh",
        "kenyan": "Kenyan",
        "kikuyu": "Kikuyu",
        "kittitian_and_nevisian": "Kittitian and Nevisian",
        "komi": "Komi",
        "kongo": "Kongo",
        "korean": "Korean",
        "kpelle": "Kpelle",
        "kru": "Kru",
        "kumam": "Kumam",
        "kurdish": "Kurdish",
        "kuwaiti": "Kuwaiti",
        "kyrgyz": "Kyrgyz",
        "laotian": "Laotian",
        "latvian": "Latvian",
        "lebanese": "Lebanese",
        "liberian": "Liberian",
        "libyans": "Libyans",
        "lingala": "Lingala",
        "lithuanian": "Lithuanian",
        "luba": "Luba",
        "luganda": "Luganda",
        "lunda": "Lunda",
        "luo": "Luo",
        "luxembourgish": "Luxembourgish",
        "macedonian": "Macedonian",
        "madagascan": "Madagascan",
        "malagasy": "Malagasy",
        "malaysian": "Malaysian",
        "malian": "Malian",
        "maltese": "Maltese",
        "mandinka": "Mandinka",
        "manx": "Manx",
        "maori": "Maori",
        "martinican": "Martinican",
        "mauritian": "Mauritian",
        "mayan": "Mayan",
        "mexican": "Mexican",
        "moldovan": "Moldovan",
        "mongolian": "Mongolian",
        "montenegrin": "Montenegrin",
        "montserratian": "Montserratian",
        "moroccan": "Moroccan",
        "mossi": "Mossi",
        "native_pacific_islander": "Native Pacific Islander",
        "ndebele": "Ndebele",
        "nenets": "Nenets",
        "nepali": "Nepali",
        "nicaraguan": "Nicaraguan",
        "nigerian": "Nigerian",
        "norwegian": "Norwegian",
        "not_sure": "Not sure",
        "omani": "Omani",
        "oromo": "Oromo",
        "ossetian": "Ossetian",
        "other": "Other",
        "pakistani": "Pakistani",
        "palauan": "Palauan",
        "palestinian": "Palestinian",
        "panamanian": "Panamanian",
        "paraguayan": "Paraguayan",
        "parsi": "Parsi",
        "pashtun": "Pashtun",
        "persian": "Persian",
        "peruvian": "Peruvian",
        "peul": "Peul",
        "polish": "Polish",
        "portuguese": "Portuguese",
        "puerto_rican": "Puerto Rican",
        "punjabi": "Punjabi",
        "qatari": "Qatari",
        "rohingya": "Rohingya",
        "romanian": "Romanian",
        "russian": "Russian",
        "rwandan": "Rwandan",
        "saint_lucian": "Saint Lucian",
        "saint_vincentian_and_grenadine": "Saint Vincentian and Grenadine",
        "salvadoran": "Salvadoran",
        "sami": "Sami",
        "samoan": "Samoan",
        "saudi_arabian": "Saudi Arabian",
        "scandinavian": "Scandinavian",
        "scottish": "Scottish",
        "senufo": "Senufo",
        "sephardic_jewish": "Sephardic Jewish",
        "serbian": "Serbian",
        "shona": "Shona",
        "sicilian": "Sicilian",
        "sierra_leonean": "Sierra Leonean",
        "sindhi": "Sindhi",
        "singaporean": "Singaporean",
        "sint_maarten": "Sint Maarten",
        "slavic": "Slavic",
        "slovak": "Slovak",
        "slovene": "Slovene",
        "soga": "Soga",
        "somali": "Somali",
        "songhai": "Songhai",
        "sorbian": "Sorbian",
        "sotho": "Sotho",
        "spanish": "Spanish",
        "sri_lankan": "Sri Lankan",
        "sudanese": "Sudanese",
        "surinamese": "Surinamese",
        "swazi": "Swazi",
        "swedish": "Swedish",
        "swiss": "Swiss",
        "syrian": "Syrian",
        "taiwanese": "Taiwanese",
        "tajik": "Tajik",
        "tamil": "Tamil",
        "tanzanians": "Tanzanians",
        "thai": "Thai",
        "tibetan": "Tibetan",
        "tigrinya": "Tigrinya",
        "togolese": "Togolese",
        "tongan": "Tongan",
        "trinidadian_and_tobagonian": "Trinidadian and Tobagonian",
        "tshiluba": "Tshiluba",
        "tuareg": "Tuareg",
        "tunisian": "Tunisian",
        "turkish": "Turkish",
        "turkmen": "Turkmen",
        "turks_and_caicos_islander": "Turks and Caicos Islander",
        "tutsi": "Tutsi",
        "u.s._virgin_islander": "U.S. Virgin Islander",
        "ugandan": "Ugandan",
        "uighur": "Uighur",
        "ukrainian": "Ukrainian",
        "uruguayan": "Uruguayan",
        "uzbek": "Uzbek",
        "venezuelan": "Venezuelan",
        "vietnamese": "Vietnamese",
        "welsh": "Welsh",
        "wolof": "Wolof",
        "xhosa": "Xhosa",
        "yakut": "Yakut",
        "yemeni": "Yemeni",
        "yoruba": "Yoruba",
        "zande": "Zande",
        "zimbabwean": "Zimbabwean",
        "zulu": "Zulu",
}

export const PronounOptions = [
    {
        label: "She/her",
        value: "she_her"
    },
    {
        label: "He/him",
        value: "he_him"
    },
    {
        label: "They/them",
        value: "they_them"
    },
    {
        label: "She/they",
        value: "she_they"
    },
    {
        label: "He/they",
        value: "he_they"
    },
    {
        label: "Other",
        value: "other"
    },
]

export const JewishAncestries = [
    {
        label:     "Maternal side",
        value:     "yes_maternal_side",
    },
    {
        label:     "Paternal side",
        value:     "yes_paternal_side",
    },
    {
        label:     "Both sides",
        value:     "yes_both_sides",
    },
    {
        label: "Either",
        value: "either",
    },
]

export const IdealTimeframe = [
    {
        label: "ASAP",
        value: "asap",
    },
    {
        label: "1-3 months",
        value: "1_2_months",
    },
    {
        label: "4-6 months",
        value: "4_6_months",
    },
    {
        label: "More than 6 months",
        value: "more_than_6",
    },
]

export const DonorRelationship = [
    {
        label: "Disclosed",
        value: "disclosed",
    },
    {
        label: "Undisclosed",
        value: "undisclosed",
    },
    {
        label: "Either",
        value: "either",
    },
    {
        label: "Not sure",
        value: "not_sure",
    }
]

export const DegreeTypesMap = {
    "aa": "AA",
    "as": "AS",
    "ba": "BA",
    "bs": "BS",
    "dds": "DDS",
    "dnp": "DNP",
    "do": "DO",
    "dvm": "DVM",
    "edd": "EdD",
    "jd": "JD",
    "ma": "MA",
    "mba": "MBA",
    "md": "MD",
    "med": "MEd",
    "meng": "MEng",
    "mfa": "MFA",
    "mj": "MJ",
    "mpa": "MPA",
    "mph": "MPH",
    "mpp": "MPP",
    "ms": "MS",
    "msw": "MSW",
    "pharmd": "PharmD",
    "phd": "PhD",
    "psyd": "PsyD",
    "rn": "RN",
}

export const FriendlySlugTranslation = {
    "bachelors_degree": "Bachelor's degree",
    "masters_degree": "Master's degree",
    "technical_degree_certification": "Technical degree/certification",
    "asl": "ASL",
    "non_profit": "Non-profit",
    "single_never_married": "Single/never married",
    "e_commerce": "E-commerce",
    "news_podcast": "News/podcast",
    "friend_word_of_mouth": "Friend/word of mouth",
    "men_having_babies": "Men Having Babies",
    "gays_with_kids": "Gays With Kids",
}











